import React, { useState, useEffect } from "react";
import { Container, Row,Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import moment from "moment";
import api from "../../../actions/riveroapi";
import { navigate } from "gatsby";
import headerLogo from "../../../images/icons/logonissa_rivero_negro.png"

import TagManager from 'react-gtm-module';

export default function FormularioTallerServicio(){

    const [ horarios, setHorarios ] = useState();
    const [ fechaHoy, setFechaHoy] = useState();
    const [ fechaManana, setFechaManana] = useState();
    const [ carroSelect, setCarroSelect ] = useState();
    const [ carrosCliente, setcarrosCliente] = useState([]);
    const [ carroOtro, setCarroOtro ] = useState(false);
    const [ horarioSelect, setHorarioSelect ] = useState();
    const [ sucursal, setSucursal ] = useState(null);
    const [ sucursalId, setSucursalId ] = useState();
    const [ marca, setMarca ] = useState('NISSAN');
    const [ marcaId, setMarcaId ] = useState('NISSAN');
    const [ phone, setPhone ] = useState();
    const [ mail, setMail ] = useState();
    const [ citaID, setCitaID ] = useState();
    const [ validadorCarros, setValidadorCarros] = useState(false);
    const [estado, setEstado] = useState(false);

    const [ cargandoHorarios, setCargandoHorarios ] = useState(true);

    const [ campos, setCampos ] = useState({
        Nombre: "",
        Telefono: "",
        Correo: "",
        Comentario: ""
    })

    const [ siguientepaso, setSiguientePaso ] = useState({
        paso1: true,
        paso2: false,
        paso3: false,
        paso4: false,
        paso5: false,
        paso6: false
    });

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const [ modelosCars, setModelosCars ] = useState(<>
        <option value="1">Selecciona</option><option value="AVEO">AVEO</option><option value="BEAT">BEAT</option><option value="BEAT SEDAN">BEAT SEDAN</option><option value="BLAZER">BLAZER</option><option value="CAMARO">CAMARO</option><option value="CAPTIVA">CAPTIVA</option><option value="CAVALIER">CAVALIER</option><option value="CHEYENNE">CHEYENNE</option><option value="COLORADO">COLORADO</option><option value="EQUINOX">EQUINOX</option><option value="GROOVE">GROOVE</option><option value="ONIX">ONIX</option><option value="SILVERADO">SILVERADO</option><option value="SPARK NG">SPARK NG</option><option value="SUBURBAN">SUBURBAN</option><option value="TAHOE">TAHOE</option><option value="TORNADO">TORNADO</option><option value="TRACKER">TRACKER</option><option value="TRAVERSE">TRAVERSE</option><option value="TRAX">TRAX</option><option value="OTRO">OTRO</option>
    </>);

    let tagManagerArgs = {
        gtmId: 'GTM-W7HQJGC6', //Este ID se obtiene en el admin de GTM
        dataLayer: {
            'event': 'dd_taller_cita_servicio',
            'auto_marca': 'Nissan',
            'auto': carroSelect,
            'sucursal': sucursal,
            'fecha': fechaManana, // yyyy-mm-dd
            'horario': horarioSelect?.time,
        }
    }

    useEffect(() => {
        
        let masdia = (moment().date() + 1 );

        setFechaHoy(moment().format("YYYY-MM-DD"))
        setFechaManana(moment().date(masdia).format("YYYY-MM-DD"));
        let iniciodia = moment().date(masdia).format("YYYY-MM-DD");

        //getHorariosCitas(iniciodia, "Effect");
        
    }, []);

    function onSelectSucursal(sucursal){ 
        //console.log("Sucursal: ", sucursal);
        setSucursalId(sucursal.target.value);
        switch(sucursal.target.value){
            case '1043194': //RIVERO CONTRY
              setSucursal("Rivero Contry");
            break;
            case '599457775': //RIVERO VALLE
              setSucursal("Rivero Valle");
            break;
            case '279130042': //RIVERO LAS TORRES
              setSucursal("Rivero Las Torres");
            break;
        }
        getHorariosCitas(sucursal.target.value, "Sucursal")

    }
    
    function onSelectMarca(marca){ 
      //console.log("Marca: ", marca);
      setMarcaId(marca.target.value);
    }

    function onChangeFecha (e){
        //console.log("Selecciono día: ", e.target.value);
        setFechaManana(e.target.value);
        getHorariosCitas(e.target.value, "Fecha")
    }

    function getHorariosCitas(dato, nombre){
        setCargandoHorarios(true);
        //console.log("inicia horariosCita: ", dato)
        let obj;
        if(nombre === "Sucursal"){
            obj = { idSucursal: dato, day: fechaManana}
        } else if(nombre === "Fecha") {
            obj = { idSucursal: sucursalId, day: dato}
        } else {
            obj = { idSucursal: sucursalId, day: dato}
        }
        //console.log("Estamos para consultar el horario: ",obj);

        api.getPlanningTallerServicio(obj).then((resp) => {
            setCargandoHorarios(false);
            //console.log("Recibo: ", resp);
            let horarios = resp.data?.times;
            let filterAgentes = horarios.filter((v) => v.availableAgents != '');
            //console.log("Estamos para consultar el horario: ", filterAgentes);
            setHorarios(filterAgentes)
        }).catch((error) => console.log(error));

    }

    function onChangeCarro(carro){
        //console.log("Se selecciono el carro: ", carro.target.value);

        if (carro.target.value !== "OTRO"){
          setCarroOtro(false);
        } else {
          setCarroOtro(true);
        }

        if(carro.target.value === "1"){
            setValidadorCarros(false)
        } else {
            setValidadorCarros(true)
            setCarroSelect(carro.target.value);
        }
    }

    function onCheckClient(){ 

      if (campos.Telefono != "" &&  campos.Telefono.length === 10 && /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(campos.Correo)){
        /*api.getClientInfo(campos).then((resp) => {
          console.log( 'Entro Api NEW', resp)
    
          if (resp.code === 200){
    
            for (var i=0; i < resp.data.length; i++){
              carrosCliente.push(resp.data[0].clientCars[i].model)
            }

            console.log(resp.data[0].clientInfo.name)
            campos.Nombre = resp.data[0].clientInfo.name;
            console.log(campos)
    
            setSiguientePaso({
              ...siguientepaso,
              paso1: false,
              paso2: false,
              paso3: true,
              paso4: false,
              paso5: false,
              paso6: false
          });

          } else if (resp.data.code === 400) {
            setSiguientePaso({
              ...siguientepaso,
              paso1: false,
              paso2: false,
              paso3: false,
              paso4: true,
              paso5: false,
              paso6: false
          });
          }
    
        }).catch((error) => alert('Ha ocurrido un error. Intentelo más tarde.'));*/
        setSiguientePaso({
          ...siguientepaso,
              paso1: false,
              paso2: false,
              paso3: false,
              paso4: true,
              paso5: false,
              paso6: false
          });
      
      } else {
        if (campos.Telefono == "" || campos.Telefono.length < 10){
          alert('Ingrese correctamente su número de telefono')
        } else {
          alert('Ingrese correctamente su correo electrónico')
        }
      }

    }

    function onCheckCarroClient(paso){
      console.log(paso)
      if (paso === "3"){

        if (carroSelect === "" || carroSelect === "1" ){
          alert('Favor de seleccionar un auto')
        } else {
          console.log('SELECCIONE: ', carroSelect)
          setSiguientePaso({
            ...siguientepaso,
            paso1: false,
            paso2: false,
            paso3: false,
            paso4: false,
            paso5: true,
            paso6: false
        });
        }

      } else {
        if (campos.Nombre === "" || carroSelect === "1" ){
          alert('Favor de llenar los campos vacios')
        } else {
          console.log('SELECCIONE: ', carroSelect)
          setSiguientePaso({
            ...siguientepaso,
            paso1: false,
            paso2: false,
            paso3: false,
            paso4: false,
            paso5: true,
            paso6:false
        });
        }
      }
        console.log(campos)
    }

    const mappingCarrosCliente = carrosCliente?.map((carro, i) => {
      return(
      <>
        <option value="">Selecciona</option><option value={carro}>{carro}</option>
      </>
      )
    });

    function onClickBotton(event, dato){
        if(dato === "Siguiente") {
            if(horarioSelect){
                setSiguientePaso({
                    ...siguientepaso,
                    paso1: false,
                    paso2: true,
                    paso3: false
                });
            } else if(horarioSelect === undefined) {
                alert("No se ha seleccionado hora");
            }
        } else if(dato === "Confirmar"){
            
        }
    }

    const mappingHorarios = horarios?.map((obj, i) => {
        let horas = obj.time;
        let horaSplit = horas.split(/:/, 3);
        let horaConcatena = `${horaSplit[0]}:${horaSplit[1]}`;
        
        return(
            <div key={i} className={horarioSelect?.time === obj.time ? "btn-horario-disponible-tallerservicio-activo" : "btn-horario-disponible-tallerservicio"} onClick={() => setHorarioSelect(obj)}>
                {horaConcatena}
            </div>
        )
    });

    const onSubmit = (data) => { 

        setEstado(true);

        let obj = {
            name: data.Nombre,
            date: fechaManana,
            time: horarioSelect.time,
            agency: sucursalId,
            mobile: data.Telefono,
            model: carroSelect,
            comment: data.Comentarios,
            ownerId: horarioSelect.availableAgents[0],
        }

       api.postPlanningTallerServicio(obj).then((resp) => {
        //console.log("Resp de la cita: ", resp)       
          
            if(resp.code === 200){

                TagManager.initialize(tagManagerArgs)
                console.log(tagManagerArgs, "Analytics");

                let objMail = {
                    name: data.Nombre,
                    date: fechaManana,
                    time: horarioSelect.time,
                    agency: sucursalId,
                    mobile: data.Telefono,
                    model: carroSelect,
                    mail: campos.Correo,
                    qr: resp.result.data.planning[0].id
                } 
                sendEmail(objMail);

                setCitaID(resp.result.data.planning[0].id);
                //console.log("ID: ",resp.result.data.planning[0].id);

                setSiguientePaso({
                    ...siguientepaso,
                    paso1: false,
                    paso2: false,
                    paso3: false,
                    paso4: false,
                    paso5: false,
                    paso6: true
                });
            } else {
                alert('Algo salió mal, inténtelo más tarde.');
                setEstado(false);
            }

        }).catch((error) => console.log(error));
        //errors.Telefono || errors.Nombre && alert("Campos requeridos")
    };

    function sendEmail(data) {
    
        api.sendEmailLeads(data).then((resp) => {
            console.log("Recibo: ", data);

        }).catch((error) => console.log(error));
    }
    
    
    return(
        <Form>
            { siguientepaso.paso1 === true ? (
                <>
                    {/*<Form.Group hidden className="pb-3">
                        <Form.Label htmlFor="name">¿Cuál es la marca de tu auto?</Form.Label><br/>
                        <Form.Select style={{width: '100%'}} onChange={(e) => onSelectMarca(e)}>
                            <option value="1" selected disabled>Selecciona</option>
                            <option value="CHEVROLET" >Chevrolet</option>
                            <option value="NISSAN" disabled>Nissan</option>
                            <option value="BUICK" disabled>Buick</option>
                            <option value="CADILLAC" disabled>Cadillac</option>
                            <option value="GMC" disabled>GMC</option>
                        </Form.Select>
                    </Form.Group>*/}
                    <Form.Group className="pb-3">
                        <Form.Label htmlFor="name">Elige tu sucursal</Form.Label><br/>
                        <Form.Select style={{width: '100%'}} onChange={(e) => onSelectSucursal(e)}>
                          <option value="0">Seleccionar</option>
                          <option value="1043194">Rivero Contry</option>
                          <option value="279130042">Rivero Las Torres</option>
                          <option value="599457775">Rivero Valle</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="pb-3">
                        <Form.Label htmlFor="name">Fecha de cita</Form.Label>
                        <Form.Control
                            onChange={(e) => onChangeFecha(e)}
                            value={fechaManana}
                            min={fechaHoy}
                            type="date"
                            name="duedate"
                        />
                    </Form.Group>

                    { cargandoHorarios === false ? (
                    <Form.Group className="pb-3">
                        <Form.Label htmlFor="name">Elige un horario disponible</Form.Label>
                        <div className="container-horarios-tallerservicio">
                            {mappingHorarios}
                        </div>
                    </Form.Group>
                    ) : (null)}

                    {/*<Form.Group className="pb-3">
                        <Form.Label htmlFor="name">¿Cuál es tu modelo de carro?</Form.Label>
                        <select isValid={validadorCarros} onChange={(e) => onChangeCarro(e)}>
                            {modelosCars}
                        </select>
                    </Form.Group>*/}

                    <Form.Group>
                        <center>
                            <Button className="btn-rojo-siguiente" onClick={(e) => onClickBotton(e, "Siguiente")}>Siguiente</Button>
                        </center>
                    </Form.Group>
                </>)
            : null }

            { siguientepaso.paso2 === true ? (
                    <>

                    <div style={{borderBottom: '2px solid red', borderRadius: '15px' , padding: '.5em', color: 'gray' , textAlign: 'center'}}>Sucursal {sucursal} -  {horarioSelect.time.substr(0,5)}</div><hr/>
    
                        <Form.Group className="pb-3" hidden>
                            <Form.Label>*Teléfono: </Form.Label>
                            <Form.Control placeholder="Teléfono (10 dígitos)" value={horarioSelect.time}/>
                        </Form.Group>
    
                        <Form.Group className="pb-3" isValid>
                            <Form.Label>*Teléfono: </Form.Label>
                            <Form.Control placeholder="Teléfono (10 dígitos)" maxLength={10} 
                            onKeyUp={(e) => setCampos({...campos, Telefono: e.target.value})}
                            {...register("Telefono", { required: true,pattern: { value: /^(?:\D*\d){10}\D*$/}, })} 
                            style={{ borderColor: errors.Telefono ? "red" : "#dee2e6" }}/>
                        </Form.Group>

                       <Form.Group className="pb-3" isValid>
                            <Form.Label>*Correo electrónico: </Form.Label>
                            <Form.Control placeholder="ejemplo@gruporivero.com" 
                            onKeyUp={(e) => setCampos({...campos, Correo: e.target.value})}
                            {...register("Correo", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} 
                            style={{ borderColor: errors.Correo ? "red" : "#dee2e6" }}/>
                        </Form.Group>
    
                        <Form.Group className="pb-3" hidden>
                            <Form.Label>Comentario: </Form.Label>
                            <Form.Control placeholder="Agregar comentario..." as="textarea"
                            {...register("Comentarios")}
                            />
                        </Form.Group>


                        <Form.Group>
                          <center>
                              <Button className="btn-rojo-siguiente" onClick={(e) => onCheckClient(e)}>Siguiente</Button>
                          </center>
                        </Form.Group>
     

                    </>
            ) : null }

            {siguientepaso.paso3 === true ? (
                <>
                    <Form.Group className="pb-3 d-none">
                        <Form.Label htmlFor="name">*Nombre: </Form.Label>
                        <Form.Control onlyread value={campos.Nombre}
                        {...register("Nombre", { required: true })} 
                        />
                            
                    </Form.Group> 

                    <Form.Group className="pb-3">
                        <Form.Label htmlFor="name">Selecciona tu auto:</Form.Label>
                        <select style={{width: '100%'}} onChange={(e) => onChangeCarro(e)}>
                            {mappingCarrosCliente}
                        </select>
                    </Form.Group>

                    <Form.Group>
                      <center>
                          <Button className="btn-rojo-siguiente" onClick={(e) => onCheckCarroClient("3")}>Siguiente</Button>
                      </center>
                    </Form.Group>
                </>         
            ) : null }

            {siguientepaso.paso4 === true ? (
                <>
                    
                    <Form.Group className="pb-3">
                        <Form.Label htmlFor="name">Nombre:</Form.Label>
                        <Form.Control placeholder="Escribe tu nombre"
                            onKeyUp={(e) => setCampos({...campos, Nombre: e.target.value})}
                            {...register("Nombre", { required: true })} 
                            style={{ borderColor: errors.Nombre ? "red" : "#dee2e6" }}/>
                    </Form.Group>

                    <Form.Group className="pb-3">
                        <Form.Label htmlFor="name">Selecciona el modelo de tu auto:</Form.Label><br/>
                        <Form.Select style={{width: '100%'}} onChange={(e) => onChangeCarro(e)}>
                          <option value="">Selecciona</option><option value="ALTIMA">ALTIMA</option><option value="FRONTIER">FRONTIER</option><option value="KICKS">KICKS</option><option value="MARCH">MARCH</option><option value="NP300">NP300</option><option value="PATHFINDER">PATHFINDER</option><option value="SENTRA">SENTRA</option><option value="URVAN">URVAN</option><option value="V-DRIVE">V-DRIVE</option><option value="VERSA">VERSA</option><option value="XTRAIL">XTRAIL</option><option value="Z">Z</option><option value="OTRO">OTRO</option>
                        </Form.Select>
                    </Form.Group>

                    {carroOtro === true ? (
                    <Form.Group className="pb-3">
                        <Form.Label htmlFor="name">Escribe el modelo de tu auto:</Form.Label><br/>
                        <Form.Control style={{width: '100%'}} 
                            onKeyUp={(e) => setCarroSelect(e.target.value)}>
                        </Form.Control>
                    </Form.Group>
                    ): null }

                    <Form.Group>
                      <center>
                          <Button className="btn-rojo-siguiente" onClick={(e) => onCheckCarroClient("4")}>Siguiente</Button>
                      </center>
                    </Form.Group>

                </>         
            ) : null }

            {siguientepaso.paso5 === true ? (
                <>
                <div style={{borderTop: '2px solid #d40028', borderLeft: '1px solid #d40028' , borderRadius: '15px' , padding: '10px', boxShadow: '1px 2px 3px 1px'}}>

                    <center> <img src={headerLogo} alt="Grupo Rivero" style={{ width: "170px" , alignSelf: "center"}} /> </center>

                    <center><h5>Confirma tus datos y registra tu cita</h5></center><hr/>

                    <Row className="p-2">
                        <Col md={6} lg={6}><center>Fecha de cita:</center></Col>
                        <Col md={6} lg={6}><center>{fechaManana}</center></Col>
                    </Row>  
                    <Row className="p-2">
                        <Col md={6} lg={6}><center>Hora:</center></Col>
                        <Col md={6} lg={6}><center>{horarioSelect.time}</center></Col>
                    </Row>  
                    <Row className="p-2">
                        <Col md={6} lg={6}><center>Sucursal:</center>
                        </Col><Col md={6} lg={6}><center>{sucursal}</center></Col>
                    </Row>
                    <Row className="p-2">
                        <Col md={6} lg={6}><center>Auto:</center></Col>
                        <Col md={6} lg={6}><center>{carroSelect}</center></Col>
                    </Row>
                </div>

                <div className="mt-3">
                <Form.Group>
                      <center>
                        {estado == false ? (
                            <Button className="btn-rojo-siguiente" onClick={handleSubmit(onSubmit)}>Registrar Cita</Button>
                        ) : (
                            <p id="alertaAsesor">Registrando cita...</p>
                        )}
                          
                      </center>
                  </Form.Group>
                </div>

                </>         
            ) : null }

            {siguientepaso.paso6 === true ? (
                <>
                <div style={{borderTop: '2px solid #d40028', borderLeft: '1px solid #d40028' , borderRadius: '15px' , padding: '10px', boxShadow: '1px 2px 3px 1px'}}>

                    <center> <img src={headerLogo} alt="Grupo Rivero" style={{ width: "170px" , alignSelf: "center"}} /> </center> <hr/>

                    <center><h5>Resumen de Cita</h5></center><hr/>

                    <Row className="pl-4 pr-4">
                        <Col md={4} lg={4}><strong>Fecha de cita:</strong></Col>
                        <Col md={8} lg={8}>{fechaManana}</Col>
                    </Row>
                    <hr/>
                    <Row className="pl-4 pr-4">
                        <Col md={4} lg={4}><strong>Hora:</strong></Col>
                        <Col md={8} lg={8}>{horarioSelect.time}</Col>
                    </Row>
                    <hr/>
                    <Row className="pl-4 pr-4">
                        <Col md={4} lg={4}><strong>Sucursal:</strong>
                        </Col><Col md={8} lg={8}>{sucursal}</Col>
                    </Row>
                    <hr/>
                    <Row className="pl-4 pr-4">
                        <Col md={4} lg={4}><strong>Auto:</strong></Col>
                        <Col md={8} lg={8}>{carroSelect}</Col>
                    </Row>

                    <Row className="pl-4 pr-4">
                        <Col md={12} lg={12}><center><img src={`https://qr-generator.rivero.services/?chl=${citaID}`} /> </center></Col>
                        <Col md={12} lg={12}><center>Se ha registrado tu cita con exito, hemos enviado la información a tu correo.</center></Col>
                    </Row>

                </div>

                <div className="mt-3">
                <Form.Group>

                  </Form.Group>
                </div>

                </>         
            ) : null }

        </Form>
    )

}